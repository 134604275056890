import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Headings from "../components/headings"
import NewsGrid from "../components/news-grid"
import PageAdvert from "../components/page-advert"
//import FeaturedArticle from "../components/featured-article"
import CtaSlider from "../components/cta-slider"

export const query = graphql`
  query Category($slug: String!) {
    articles: allStrapiArticle(
      filter: { category: { slug: { eq: $slug } } }
      sort: { order: DESC, fields: strapi_id }
    ) {
      nodes {
        ...StrapiArticles
      }
    }
    category: strapiCategory(slug: { eq: $slug }) {
      name
      slug
      description
      content {
        data
      }
      news_advert_overlays {
        title
        heading
        description
        buttonName
        link
        id
      }
    }
    
    strapiNews {
      hero {
        ...StrapiHero
      }
      featuredArticle {
        ...StrapiFeaturedArticle
      }
      callToAction {
        ...StrapiCallToAction
      }
    }
    allStrapiNewsAdvertOverlay {
      nodes {
        title
        heading
        description
        buttonName
        link
        id
        image {
          alternativeText
          localFile {
            url
            childImageSharp {
              gatsbyImageData(
                formats: AUTO
                layout: CONSTRAINED
                placeholder: BLURRED
                aspectRatio: 1.1
              )
            }
          }
        }
      }
    }
    allStrapiPageAdvert(filter: {title: {in: ["Customer Centric Content", "Engage, Share, and Stay Connected"]}}) {
      nodes {
        title
        heading
        description
        buttonName
        link
        image {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(formats: AUTO, layout: CONSTRAINED, placeholder: DOMINANT_COLOR, aspectRatio: 1.40, height: 600)
            }
          }
        }
        style
        enabled
        content {
          data {
            content
          }
        }
      }
    }
  }  
`

const Category = ({ data, pageContext }) => {
  //console.log("Category pageContext: ", pageContext)
  //console.log("Category - data:", data)

  //console.log("Category articles: ", data.articles.nodes)
  //console.log("Category news:", data.strapiNews)
  //console.log("Category category:", data.category)
  // console.log(
  //   "Category allStrapiNewsAdvertOverlay:",
  //   data.allStrapiNewsAdvertOverlay
  // )
  //console.log("Category category ads:", data.category.news_advert_overlays)
  //console.log("allStrapiNewsAdvertOverlay: ", data.allStrapiNewsAdvertOverlay)

  // get title
  /* const categoryAds = data.category.news_advert_overlays.map((item, index) => {
    return item
  })
  console.log("categoryAds: ", categoryAds) */

  //const featuredArticle = data.strapiNews.featuredArticle
  //console.log("featuredArticle:", featuredArticle)

  const callToAction = data.strapiNews.callToAction
  //console.log("callToAction: ", callToAction)

  const callToActionSections =
    data.strapiNews.callToAction.call_to_action_sections
  //console.log("callToActionSections: ", callToActionSections)

  const adverts = []

  /*  data.category.news_advert_overlays.map(news_advert_overlay => {
    //console.log("news_advert_overlay: ", news_advert_overlay)
    if (news_advert_overlay.title.length > 0) {
      return data.allStrapiNewsAdvertOverlay.nodes.map(ad => {
        if (news_advert_overlay.title === ad.title) {
          return adverts.push({ ...ad })
        }
      })
    }
  }) */

  data.category.news_advert_overlays.map(news_advert_overlay => {
    return (
      <>
        {
          data.allStrapiNewsAdvertOverlay.nodes.map(ad => {
            if (news_advert_overlay.title === ad.title) {
              return adverts.push({ ...ad })
            }
          })
        }
      </>
    )
  })

  /* data.category.news_advert_overlays.map(news_advert_overlay =>
    //console.log("news_advert_overlay: ", news_advert_overlay)

    data.allStrapiNewsAdvertOverlay.nodes.map(ad => {
      if (news_advert_overlay.title === ad.title) {
        return adverts.push({ ...ad })
      }
    })
  ) */

  //console.log("Category adverts:", adverts)

  const articles = data.articles.nodes
  const category = data.category.name
  const content = data.category.content.data
  const hero = data.strapiNews.hero
  //const categoryAdverts = data.category.news_advert_overlays
  //console.log("Category category ads:", categoryAdverts)
  //console.log("Article:", articles)

  const customerCentricContent = data.allStrapiPageAdvert.nodes[0]
  const engageShareAndStayConnected = data.allStrapiPageAdvert.nodes[1]
  //console.log("Category customerCentricContent:", customerCentricContent)
  //console.log("Category engageShareAndStayConnected:", engageShareAndStayConnected)



  return (
    <>
      <Seo title={category} description={`All ${category} articles`} />

      <Hero hero={hero} />

      <section className="uk-section uk-section-default uk-padding-remove">
        <div className="uk-container uk-margin-small-bottom">
          <Headings
            title={category}
            description={content}
          />
          
          <PageAdvert advert={customerCentricContent} background="uk-background-default" />

          <NewsGrid
            articles={articles}
            categoryAdverts={adverts}
          />

          <PageAdvert advert={engageShareAndStayConnected} background="uk-background-default" />
        </div>
      </section>

      <section className="uk-section uk-section-muted uk-padding-remove">
        <div className="uk-container uk-margin-medium-bottom">
            <Headings
              title={callToAction.title}
              description={callToAction.description}
            />
          <div className="uk-container uk-margin">
            <CtaSlider callToActionSections={callToActionSections} />
          </div>
        </div>
      </section>
    </>
  )
}

export default Category
